@import "global";
.sedleckyKaolin {
	$this:&;
	z-index: 4;

	&__letter {
		left:245px;
	}

	.citrus {
		top: 0;
		right: -83px;
		background-color: rgba(#0E1132,0.1);
	}

	.section__cta {
		background-color: #3398f3;
		&:hover {
			background-color: darken(#3398f3, 4);
		}

		.arrow path {
			fill: #fff;
		}

		&:after {
			background-color: #3398f3;
		}

	}
}